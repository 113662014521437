import React from 'react';
import moment from 'moment';

class NonprofitDashboardBlogList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      blogs: [],
      isLoading: false,
      isDataError: false
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    $.ajax({
      url: 'https://4agoodcause.com/wp-json/wp/v2/posts',
      method: 'GET',
      cache: false,
      data: {
        per_page: 5
      }
    })
      .done((data) => {
        this.setState({ blogs: data });
      })
      .fail((error) => {
        this.setState({ isDataError: true });
      })
      .always(() => {
        this.setState({ isLoading: false });
      });
  }

  renderBlogs() {
    const { blogs } = this.state;

    if (blogs.length === 0) {
      return <p className="dashboard__no-activity p-5">No recent blogs.</p>;
    }

    return blogs.map((blog) => {
      const blogDate = moment.parseZone(blog.date).format('MMM D, YYYY');
      const blogTitle = { __html: blog.title.rendered };

      return (
        <a
          href={blog.link}
          key={blog.id}
          target="_blank"
          className="block py-4 px-5 border-b border-neutral-200 hover:bg-neutral-100 text-neutral-900">
          <h4 dangerouslySetInnerHTML={blogTitle}></h4>
        </a>
      );
    });
  }

  renderBody() {
    if (this.state.isLoading) {
      return <div className="dashboard-loading text-sm text-neutral-600">Loading...</div>;
    }

    if (this.state.isDataError) {
      return <p className="dashboard__error p-5">This data cannot be shown at this time.</p>;
    }

    return this.renderBlogs();
  }

  render() {
    const body = this.renderBody();

    return (
      <div className="dashboard-blog-posts dash-block">
        <header className="py-4 px-5 border-b border-solid border-neutral-200">
          <h3 className="text-h5">
            From the 4aGC Blog
          </h3>
        </header>
        <div className="divide-y divide-neutral-200 divide-solid">
          {body}

          <p className="p-5 text-sm">
            <a href="https://4agoodcause.com/blog" target="_blank" className="underline hover:no-underline">More Posts</a>
          </p>
        </div>
      </div>
    );
  }
}

export default NonprofitDashboardBlogList;
