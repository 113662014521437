import React from 'react';
import Autosuggest from 'react-autosuggest';

class DonorImportTagsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: this.props.tags,
      value: '',
      suggestions: this.props.suggestions,
      popularSuggestions: this.props.popularSuggestions,
      popularTags: this.props.popularTags,
      tagError: ''
    };

    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
  }

  addTag(tagName) {
    const tags = this.state.tags;

    if (tags.indexOf(tagName) > -1) {
      return;
    }

    tags.push(tagName);
    this.setState({ tags: tags });
  }

  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  getSuggestions(value) {
    const escapedValue = this.escapeRegexCharacters(value.trim());
    const regex = new RegExp('^' + escapedValue, 'i');

    const availableSuggestions = this.props.suggestions.filter(tag => {
      return this.state.tags.indexOf(tag) === -1;
    });

    const suggestions = availableSuggestions.filter(tag => regex.test(tag));

    if (!suggestions.length) {
      return [
        { isAddNew: true }
      ]
    }

    return suggestions;
  }

  getSuggestionValue(suggestion) {
    if (suggestion.isAddNew) {
      return this.state.value;
    }

    return suggestion;
  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
      tagError: ''
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  }

  onSuggestionSelected(event, { suggestionValue }) {
    event.preventDefault();

    if (!suggestionValue) {
      this.setState({ tagError: 'Tag name cannot be blank'})
      return false;
    }

    this.addTag(suggestionValue);
    this.setState({ value: '' });

    setTimeout(() => {
      $('[name="tag-input"]').valid();
    }, 10);
  }

  removeTag(tagName) {
    let tags = this.state.tags;
    tags = tags.filter(tag => tag != tagName);
    this.setState({ tags: tags });
  }

  renderPopularTags() {
    if (!this.props.popularSuggestions.length) {
      return null;
    }

    const tags = this.props.popularSuggestions.map((tag, index) => {
      let selectedClass;
      let input;
      const isSelected = this.state.popularTags.includes(tag);

      if (isSelected) {
        selectedClass = 'active';
        input = <input type="hidden" name={this.props.popularTagsInputName} value={tag} />
      }

      return (
        <div key={index}>
          <button
            className={`tag-item ${selectedClass}`}
            data-tag-item={tag}
            type="button"
            onClick={this.togglePopularTag.bind(this, tag)}>
            <span className="tag-item__label">{tag}</span>
          </button>

          {input}
        </div>
      )
    });

    return (
      <div className="mt-6">
        <p>Or select from popular tags</p>

        <div className="flex flex-wrap gap-2 mt-2">
          {tags}
        </div>
      </div>
    )
  }

  renderSuggestion(suggestion, { query, isHighlighted }) {
    let itemClassName, content, name;

    if (isHighlighted) {
      itemClassName = 'is-highlighted';
    }

    if (suggestion.isAddNew) {
      name = this.state.value;
      content = (
        <span>
          [+] Add new: <strong>{this.state.value}</strong>
        </span>
      );
    } else {
      name = suggestion;
      content = suggestion;
    }

    return (
      <div data-tags-suggestion={name} className={`autosuggest-item ${itemClassName}`}>
        <span className="autosuggest-item__name">
          {content}
        </span>
      </div>
    )
  }

  renderTags() {
    return this.state.tags.map((tag, index) => {
      return (
        <div key={index} className="tag-item active" data-tag-item={tag}>
          <span className="tag-item__label">{tag}</span>

          <button
            onClick={this.removeTag.bind(this, tag)}
            data-tag-remove={tag}
            type="button"
            className="tag-item__remove">
              <span className="sr-only">
                Delete
              </span>
          </button>

          <input type="hidden" name={this.props.tagsInputName} value={tag} />
        </div>
      )
    });
  }

  shouldRenderSuggestions() {
    return true;
  }

  togglePopularTag(tagName) {
    let tags = this.state.popularTags;

    if (tags.indexOf(tagName) > -1) {
      tags = tags.filter(tag => tag != tagName);
    } else {
      tags.push(tagName);
    }

    this.setState({ popularTags: tags });
  }

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: "Start typing...",
      value,
      onChange: this.onChange,
      name: 'tag-input'
    };

    let tagCountSection;

    if (!this.props.hideTagCount) {
      const tagCount = this.state.tags.length + this.state.popularTags.length;
      const tagCountLabel = tagCount === 1 ? 'tag' : 'tags';

      tagCountSection = (
        <div className="absolute mt-10 left-0 bottom-0 right-0 bg-neutral-100 px-5 py-4">
          <p className="font-medium">
            <span className="text-green">{tagCount}</span> {tagCountLabel} will be applied.
          </p>
        </div>
      )
    }

    const tags = this.renderTags();
    const popularTags = this.renderPopularTags();

    return (
      <>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={this.getSuggestionValue}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
          highlightFirstSuggestion={true}
          focusInputOnSuggestionClick={false}
        />

        {this.state.tagError && (
          <label className="error">
            {this.state.tagError}
          </label>
        )}

        {tags.length > 0 && (
          <div className="flex flex-wrap gap-2 mt-4">
            {tags}
          </div>
        )}

        {popularTags}
        {tagCountSection}
      </>
    );
  }
}

export default DonorImportTagsForm;
