const keys = [
  'nonprofit_tag_ids_in',
  'first_name_cont_any',
  'last_name_cont_any',
  'address_1_cont_any',
  'address_2_cont_any',
  'city_cont_any',
  'state_cont_any',
  'postal_cont_any',
  'country_cont_any',
  'phone_cont_any',
  'alternate_phone_cont_any',
  'email_cont_any',
  'alternate_email_cont_any',
  'newsletter_enabled_eq',
  'birthdate_month_in',
  'birthdate_day_in',
  'birthdate_year_in',
  'job_title_cont_any',
  'company_cont_any',
  'year_to_date_total_in_cents_between',
  'average_total_in_cents_between',
  'lifetime_total_in_cents_between',
  'donor_notes_body_cont_any'
];

function keyExists(key) {
  return keys.indexOf(key) > -1;
}

function keyIsField(key) {
  return key.startsWith('field:')
}

export default (filters) => {
  const object = {};

  Object.keys(filters).forEach((key) => {
    if (keyExists(key) || keyIsField(key)) {
      object[key] = filters[key];
    }
  })

  return object;
}
