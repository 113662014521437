import React from  'react';
import DonorSearchLayoutDrawer from './layout-drawer';
import DonorSearchLayoutToolbar from './layout-toolbar';
import getScript from '../../utils/get-script';
import isArrayField from '../../utils/donor-search/is-array-field';
import normalizedParams from '../../utils/donor-search/normalized-params';
import queryString from '../../utils/query-string';
import urlChange from '../../utils/url-change';

class DonorSearchContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      keyword: props.keyword,
      filters: props.filters || {}
    }

    this.removeField = this.removeField.bind(this);
    this.submit = this.submit.bind(this);
    this.submitFilter = this.submitFilter.bind(this);
    this.submitKeyword = this.submitKeyword.bind(this);
  }

  removeField(field) {
    const { filters } = this.state;

    delete filters[field];

    this.setState({ filters }, () => {
      this.submit();
      this.props.onFilterChange(filters);
    });
  }

  submit() {
    const { url } = this.props;
    const { filters, keyword } = this.state;
    const params = normalizedParams(filters, keyword);

    const query = queryString(params);

    this.setState({ isLoading: true });

    getScript(url, query).finally(() => {
      this.setState({ isLoading: false });
    });

    urlChange(url, query);
  }

  submitFilter(formState) {
    const { filters } = this.state;
    const { field, value } = formState;

    if (!isArrayField(field)) {
      filters[field] = value;
    } else {
      filters[field] = (filters[field] || []).concat(value);
    }

    this.setState({ filters }, () => {
      this.submit();
      this.props.onFilterChange(filters);
    });
  }

  submitKeyword(value) {
    const newState = { keyword: value }

    this.setState(newState, () => {
      this.submit();
      this.props.onKeywordChange(value);
    });
  }

  render() {
    const {
      apiKey,
      customFields,
      dayOptions,
      exportUrl,
      keyword,
      layout,
      monthOptions,
      sort,
      tags,
      url,
      yearOptions
    } = this.props;

    const {
      filters,
      isLoading,
      params
    } = this.state;

    const toolbarLayout = (
      <DonorSearchLayoutToolbar
        apiKey={apiKey}
        customFields={customFields}
        dayOptions={dayOptions}
        exportUrl={exportUrl}
        filters={filters}
        isLoading={isLoading}
        keyword={keyword}
        monthOptions={monthOptions}
        removeField={this.removeField}
        sort={sort}
        submitFilter={this.submitFilter}
        submitKeyword={this.submitKeyword}
        tags={tags}
        url={url}
        yearOptions={yearOptions}
      />
    );

    const drawerLayout = (
      <DonorSearchLayoutDrawer
        apiKey={apiKey}
        customFields={customFields}
        dayOptions={dayOptions}
        exportUrl={exportUrl}
        filters={filters}
        isLoading={isLoading}
        keyword={keyword}
        monthOptions={monthOptions}
        removeField={this.removeField}
        submitFilter={this.submitFilter}
        submitKeyword={this.submitKeyword}
        tags={tags}
        url={url}
        yearOptions={yearOptions}
      />
    );

    const content = layout === 'toolbar' ? toolbarLayout : drawerLayout;

    return (
      <div>{content}</div>
    );
  }
}

export default DonorSearchContainer;
