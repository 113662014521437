import React from 'react';
import TagsForm from '../../tags-form';

class CampaignFormTaggingContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.checked
    }

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange() {
    this.setState({ checked: !this.state.checked });
  }

  render() {
    const tagsForm = this.state.checked ? (
      <TagsForm
        tags={this.props.tags}
        suggestions={this.props.suggestions}
        popularTags={[]}
        popularSuggestions={[]}
        tagsInputName="tags[]"
        hideTagCount={true}
      />
    ) : null;

    return (
      <div className="form-section tw-block mb-12">
        <header className="form-header">
          <h3 className="form-title">Tagging</h3>
          <p className="px-5 text-sm">
            A tag is an identifier, a one or two-word phrase that you add to a
            donor's profile to distinguish them from other donors. If you want
            donors automatically tagged when they donate to this campaign click
            the checkbox below and enter your tag(s). Learn
            more in our <a href="https://help.4agoodcause.com/help/getting-started-with-tagging" target="_blank">tagging guide.</a>
          </p>
        </header>

        <div className="p-5">
          <div className="form-item form-checkbox">
            <input value="0" type="hidden" name="campaign[transaction_tagging_enabled]" />
            <label className="form-item__container flex" htmlFor="campaign_transaction_tagging_enabled">
              <input
                type="checkbox"
                value="1"
                name="campaign[transaction_tagging_enabled]"
                id="campaign_transaction_tagging_enabled"
                checked={this.state.checked}
                onChange={this.handleChange}
              />

              <div className="checkbox-label ml-2">Tagging enabled</div>
            </label>
          </div>

          <div>
            {tagsForm}
          </div>
        </div>
      </div>
    )
  }
}

export default CampaignFormTaggingContainer;
