import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'allCheckbox',
    'bulkAction',
    'checkbox',
    'count',
    'submit'
  ];

  changeBulkAction() {
    this.toggleSubmit();
  }

  selectRecord() {
    this.toggleSubmit();
    this.setAllCheckbox();
  }

  selectAll(event) {
    if($(event.target).is(':checked')) {
      $(this.checkboxTargets).prop('checked', true);
    } else {
      $(this.checkboxTargets).prop('checked', false);
    }

    this.toggleSubmit();
  }

  toggleSubmit() {
    if (this.checked.length && this.bulkActionHasValue) {
      $(this.submitTarget).attr('disabled', false);
      return;
    }

    $(this.submitTarget).attr('disabled', true);
  }

  setAllCheckbox() {
    const checkboxesCount = this.checkboxTargets.length;
    const checkedCount = this.checked.length;

    this.allCheckboxTarget.checked = checkedCount > 0
    this.allCheckboxTarget.indeterminate = checkedCount > 0 && checkedCount < checkboxesCount;
  }

  get checked() {
    return this.checkboxTargets.filter(checkbox => checkbox.checked);
  }

  get countLabel() {
    const label = this.checked.length > 1 ? 'Records' : 'Record';
    return `${this.checked.length} ${label}`;
  }

  get bulkActionHasValue() {
    return this.bulkActionTarget.value != '';
  }
}