import filterValueDates from './filter-value-dates';
import filterValueJoin from './filter-value-join';
import filterValueMoneyRange from './filter-value-money-range';
import isArray from './is-array';
import isBlankFilterValue from './is-blank-filter-value';
import isDateFilterValue from './is-date-filter-value';
import isMoneyRangeFilterValue from './is-money-range-filter-value';
import isTagFilterValue from './is-tag-filter-value';

export default (value, field, joiner = ' or ', nonprofitTags = []) => {
  if (isBlankFilterValue(field)) {
    return null;
  }

  if (isDateFilterValue(field)) {
    const formattedValue = filterValueDates(value, field);
    return filterValueJoin(formattedValue, joiner);
  }

  if (isMoneyRangeFilterValue(field)) {
    return filterValueMoneyRange(value);
  }

  if (isTagFilterValue(field)) {
    const names = value.map(id => {
      const tagObj = nonprofitTags.find((tag) => tag.id === id);
      return tagObj.name;
    });

    return filterValueJoin(names, joiner);
  }

  if (isArray(value)) {
    return filterValueJoin(value, joiner);
  }

  return value;
}
