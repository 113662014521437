import React from 'react';
import eventValue from '../../../utils/event-value';

const DonorSearchFilterTagField = (props) => {
  const { onChange, tags, type } = props;
  const id = `filter-input-${type}`;

  return (
    <div className="form-item form-select">
      <div className="form-item__container">
        <select
          id={id}
          onChange={event => onChange(eventValue(event))}
        >
          {tags.map((tag) => (
            <option key={tag.id} value={tag.id}>{tag.name}</option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default DonorSearchFilterTagField;
