import React from 'react';
import axios from 'axios';
import swal from 'sweetalert';

class DonorImportImport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      progressTotal: 0,
      progressAt: 0,
      disabled: true,
      status: 'not_started'
    }

    this.startImport = this.startImport.bind(this);
    this.save = this.save.bind(this);
    this.validate = this.validate.bind(this);
  }

  save() {
    const fieldMap = this.generateFieldMap();
    const { apiKey, updateUrl } = this.props;
    const data = {
      api_key: apiKey,
      nonprofit_donor_import: {
        field_map: fieldMap
      }
    }

    axios({
      url: updateUrl,
      method: 'put',
      data: data
    })
    .then((response) => {
      this.startImport();
    })
    .catch(() => {
      this.showError();
    });
  }

  startImport() {
    this.showLoading();

    const { apiKey, nonprofitDonorImportId, importUrl } = this.props;
    const data = {
      nonprofit_donor_import_id: nonprofitDonorImportId,
      api_key: apiKey
    };

    this.setState({ disabled: true });

    axios({
      url: importUrl,
      method: 'post',
      data: data
    })
    .then((response) => {
      const jobId = response.data.job_id;
      setTimeout(() => this.checkJob(jobId), 1000);
    })
    .catch(() => {
      this.showError();
      this.setState({ disabled: false });
    });
  }

  checkJob(jobId) {
    const { apiKey, reportUrl } = this.props;

    axios({
      url: `/api/nonprofits/jobs/${jobId}`,
      method: 'get',
      params: {
        api_key: apiKey
      }
    })
    .then((response) => {
      const { at, total, status } = response.data;
      this.setState({ progressAt: at, progressTotal: total, status: status })

      if (status === 'failed') {
        this.showError();
        this.setState({ disabled: false });
        return;
      }

      if (status === 'complete') {
        this.showComplete();
        return window.location.href = reportUrl;
      }

      setTimeout(() => this.checkJob(jobId), 1000);
    })
    .catch((error) => {
      this.showError();
      this.setState({ disabled: false });
    });
  }

  generateFieldMap() {
    const fieldMap = [];
    const fields = document.querySelectorAll('[name="nonprofit_donor_import[field_map][]"]');
    fields.forEach((field, index) => {
      fieldMap[index] = field.value;
    });

    return fieldMap;
  }

  validate() {
    this.setState({ disabled: !this.validMappings() });
  }

  validMappings() {
    const fieldMap = this.generateFieldMap();
    return fieldMap.includes('first_name');
  }

  showLoading() {
    swal({
      title: 'Your import is working...',
      icon: 'info',
      buttons: false
    });
  }

  showComplete() {
    swal({
      title: 'Success! Redirecting to status report...',
      icon: 'success'
    });
  }

  showError() {
    swal({
      title: 'An error occurred and your import could not be completed.',
      icon: 'error'
    });
  }

  renderMappings() {
    const { sets, customFields } = this.props;

    const customFieldOptions = customFields.map((field, index) => {
      const value = `donor_form_field_${field.id}`;
      return <option key={index} value={value}>{field.label}</option>
    })

    return sets.map((set, index) => {
      const rows = set.map((row, index) => {
        const className = index === 0 ? 'px-4 py-2 text-sm font-medium' : 'px-4 py-2 border-t border-neutral-200 border-solid text-sm';
        return (
          <div key={index}>
            <div className={className}>
              {row}
            </div>
          </div>
        )
      });

      return (
        <div key={index} data-set-index={index} className="md:grid grid-cols-2 gap-5 mb-12">
          <div className="tw-block mb-6 md:mb-0">
            {rows}
          </div>

          <div className="form-select">
            <label className="mb-2">Column {index + 1} Field</label>
            <div className="form-item__container">
              <select
                name="nonprofit_donor_import[field_map][]"
                onChange={this.validate}
              >
                <option value="">Skip Field</option>
                <option value="first_name">First Name</option>
                <option value="last_name">Last Name</option>
                <option value="address_1">Address 1</option>
                <option value="address_2">Address 2</option>
                <option value="city">City</option>
                <option value="state">State / Province</option>
                <option value="postal">Zip / Postal Code</option>
                <option value="country">Country</option>
                <option value="phone">Phone</option>
                <option value="alternate_phone">Alternate Phone</option>
                <option value="email">Email</option>
                <option value="alternate_email">Alternate Email</option>
                <option value="newsletter_enabled">Newsletter Subscriber</option>
                <option value="birthdate">Birthday</option>
                <option value="job_title">Job Title</option>
                <option value="company">Employer</option>
                {customFieldOptions}
              </select>
            </div>
          </div>
        </div>
      )
    });
  }

  render() {
    const { backUrl } = this.props;
    const { disabled, status } = this.state;
    const mappings = this.renderMappings();

    return (
      <>
        {mappings}

        <footer className="flex justify-between">
          <a href={backUrl} className="btn btn__outline">
            &larr; Back
          </a>

          <button
            className="btn btn-lime"
            onClick={this.save}
            disabled={disabled}
            data-submit="true">
            Finish
          </button>
        </footer>
      </>
    )
  }
}

export default DonorImportImport;
