import React from  'react';
import DonorSearchContainer from './container';

class DonorSearchMaster extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: props.filters || {},
      keyword: props.keyword
    }

    this.changeFilter = this.changeFilter.bind(this);
    this.changeKeyword = this.changeKeyword.bind(this);
  }

  changeFilter(filters) {
    this.setState({ filters });
  }

  changeKeyword(keyword) {
    this.setState({ keyword });
  }

  render() {
    const {
      apiKey,
      customFields,
      dayOptions,
      exportUrl,
      monthOptions,
      sort,
      tags,
      url,
      yearOptions
    } = this.props;

    const {
      filters,
      keyword
    } = this.state;

    return (
      <div className="master">
        <DonorSearchContainer
          apiKey={apiKey}
          customFields={customFields}
          dayOptions={dayOptions}
          exportUrl={exportUrl}
          filters={filters}
          keyword={keyword}
          layout='toolbar'
          monthOptions={monthOptions}
          onFilterChange={this.changeFilter}
          onKeywordChange={this.changeKeyword}
          sort={sort}
          tags={tags}
          url={url}
          yearOptions={yearOptions}
        />

        <DonorSearchContainer
          apiKey={apiKey}
          customFields={customFields}
          dayOptions={dayOptions}
          exportUrl={exportUrl}
          filters={filters}
          keyword={keyword}
          layout='drawer'
          monthOptions={monthOptions}
          onFilterChange={this.changeFilter}
          onKeywordChange={this.changeKeyword}
          sort={sort}
          tags={tags}
          url={url}
          yearOptions={yearOptions}
        />
      </div>
    )
  }
}

export default DonorSearchMaster;
