const labelMap = {
  'address_1_cont_any': 'Address 1 contains',
  'address_2_cont_any': 'Address 2 contains',
  'alternate_email_cont_any': 'Alternate Email contains',
  'alternate_phone_cont_any': 'Alternate Phone contains',
  'average_total_in_cents_between': 'Average gift between',
  'average_total_in_cents_gteq': 'Average gift greater or equal to $500',
  'birthdate_day_in': 'Birthday Day is',
  'birthdate_month_in': 'Birthday Month is',
  'birthdate_year_in': 'Birthday Year is',
  'city_cont_any': 'City contains',
  'company_cont_any': 'Employer contains',
  'country_cont_any': 'Country contains',
  'donations_count_eq': 'Without Donations',
  'donations_count_gt': 'With Donations',
  'donor_notes_body_cont_any': 'Notes contains',
  'email_blank': 'Without Email',
  'email_cont_any': 'Email contains',
  'email_present': 'With Email',
  'first_name_cont_any': 'First Name contains',
  'is_active_recurring_true': 'Active Recurring Donors',
  'is_active_12_months_true': 'Donors (Last 12 months)',
  'is_active_24_months_true': 'Donors (Last 24 months)',
  'is_active_36_months_true': 'Donors (Last 36 months)',
  'is_at_risk_true': 'At-risk Donors',
  'is_complete_address_true': 'With Complete Address',
  'is_complete_address_false': 'Without Complete Address',
  'is_lapsed_true': 'Lapsed Donors',
  'is_lybunt_true': 'LYBUNT',
  'is_new_true': 'New Donors',
  'is_sybunt_true': 'SYBUNT',
  'job_title_cont_any': 'Job Title contains',
  'last_name_cont_any': 'Last Name contains',
  'lifetime_total_in_cents_between': 'Lifetime giving between',
  'newsletter_enabled_eq': 'Newsletter Subscriber is',
  'nonprofit_tag_ids_in': 'Tagged with',
  'orders_count_eq': 'Without Registrations',
  'orders_count_gt': 'With Registrations',
  'phone_cont_any': 'Phone contains',
  'postal_cont_any': 'Zip / Postal Code contains',
  'preferred_name_cont_any': 'Preferred Name contains',
  'state_cont_any': 'State / Province contains',
  'statement_in_year': 'Received a Statement for Year',
  'statement_not_in_year': 'Has not Received a Statement for Year',
  'transaction_in_year': 'Has a Transaction in Year',
  'year_to_date_total_in_cents_between': 'Year to date giving between',
  'year_to_date_total_in_cents_gteq': 'Year to date giving greater or equal to $500'
}

function getFieldLabel(key) {
  return `${key.replace('field:', '')} contains`;
}

export default (key) => {
  if (key.startsWith('field:')) {
    return getFieldLabel(key);
  }

  return labelMap[key];
}
