const noValueFields = [
  'average_total_in_cents_gteq',
  'donations_count_eq',
  'donations_count_gt',
  'email_blank',
  'email_present',
  'is_complete_address_true',
  'is_complete_address_false',
  'is_sybunt_true',
  'is_lybunt_true',
  'is_lapsed_true',
  'is_at_risk_true',
  'is_new_true',
  'is_active_recurring_true',
  'is_active_12_months_true',
  'is_active_24_months_true',
  'is_active_36_months_true',
  'orders_count_eq',
  'orders_count_gt',
  'year_to_date_total_in_cents_gteq'
];

export default (field) => {
  return noValueFields.includes(field);
}
