import React from  'react';
import DonorSearchMenuButton from './menu-button';
import DonorSearchFieldFiltersDropDown from './field-filters-drop-down';
import objectCount from '../../utils/object-count';

class DonorSearchFieldFiltersMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false
    }

    this.clickDocument = this.clickDocument.bind(this);
    this.submit = this.submit.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.clickDocument, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.clickDocument, false);
  }

  clickDocument(e) {
    const { target } = e;
    const { node } = this.refs;

    if (!node.contains(target)) {
      this.setState({ isVisible: false });
    }
  }

  filterCount() {
    const { filters } = this.props;

    return objectCount(filters);
  }

  submit(...args) {
    const { onSubmit } = this.props;

    this.setState({ isVisible: false });

    onSubmit(...args)
  }

  toggleMenu() {
    const { isVisible } = this.state;

    this.setState({ isVisible: !isVisible });
  }

  render() {
    const { isVisible } = this.state;

    const {
      customFields,
      dayOptions,
      monthOptions,
      tags,
      url,
      yearOptions
    } = this.props;

    const menu = !isVisible ? null : (
      <DonorSearchFieldFiltersDropDown
        customFields={customFields}
        dayOptions={dayOptions}
        monthOptions={monthOptions}
        onSubmit={this.submit}
        tags={tags}
        url={url}
        yearOptions={yearOptions}
      />
    );

    return (
      <form
        ref="node"
        data-donors-filters-form
        data-field-filters-menu
        className="d-filter-form"
      >
        <div className="d-field-filters">
          <DonorSearchMenuButton
            onClick={this.toggleMenu}
            quantity={this.filterCount()}
            text="Filters"
            triggerName="field-filters"
          />

          {menu}
        </div>
      </form>
    )
  }
}

export default DonorSearchFieldFiltersMenu;
