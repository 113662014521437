import React from  'react';

import DonorSearchAccordion from './accordion';
import DonorSearchFieldFilters from './field-filters';
import DonorSearchKeyword from './keyword';
import DonorSearchQuickFilters from './quick-filters';
import DonorSearchTags from './tags';
import Loading from '../loading';

import fieldFilters from '../../utils/donor-search/field-filters';
import quickFilters from '../../utils/donor-search/quick-filters';

class DonorSearchLayoutDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    }

    this.clickFilters = this.clickFilters.bind(this);
  }

  clickFilters() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const {
      apiKey,
      customFields,
      dayOptions,
      exportUrl,
      filters,
      isLoading,
      keyword,
      monthOptions,
      tags,
      url,
      yearOptions
    } = this.props;

    const drawerClass = this.state.isOpen ? "filters-drawer drawer is-open" : "filters-drawer drawer";

    return (
      <div className="d-filters d-mobile-filters">
        <DonorSearchKeyword
          keyword={keyword}
          onSubmit={this.props.submitKeyword}
          url={url}
        />

        <div className="btn btn-filters btn__outline btn__small" onClick={this.clickFilters}>
          <span>Filters</span>
        </div>

        <div className={drawerClass}>
          <div className="filters-drawer__overlay drawer__overlay" onClick={this.clickFilters}></div>

          <div className="filters-drawer__content drawer__content drawer__content--right">
            <div className="drawer-header">
              <h4 className="title__h2">Filters</h4>

              <div className="btn--close" onClick={this.clickFilters}>
                <span className="is-hidden">Close</span>
                <svg className="icon-chevron-right ui-icon--small">
                  <use xlinkHref="#icon-chevron-right"></use>
                </svg>
              </div>
            </div>

            <DonorSearchTags
              filters={filters}
              nonprofitTags={tags}
              onRemove={this.props.removeField}
            />

            <DonorSearchAccordion title="Quick Filters">
              <DonorSearchQuickFilters
                filters={quickFilters(filters)}
                onDisable={this.props.removeField}
                onEnable={this.props.submitFilter}
              />
            </DonorSearchAccordion>

            <DonorSearchAccordion title="Filters">
              <DonorSearchFieldFilters
                customFields={customFields}
                dayOptions={dayOptions}
                filters={fieldFilters(filters)}
                monthOptions={monthOptions}
                onSubmit={this.props.submitFilter}
                tags={tags}
                url={url}
                yearOptions={yearOptions}
              />
            </DonorSearchAccordion>

          </div>
        </div>

        <Loading
          isLoading={isLoading}
          type="bar"
        />
      </div>
    );
  }
}

export default DonorSearchLayoutDrawer;
