import React from 'react';
import DonorSearchFieldFilters from './field-filters';

class DonorSearchFieldFiltersDropDown extends React.Component {
  render() {
    const {
      customFields,
      dayOptions,
      monthOptions,
      tags,
      yearOptions
    } = this.props;

    return (
      <div
        id="filter-drop-down"
        className="drop-down is-open"
        data-field-filters
      >
        <div className="drop-down--wrapper viewport">
          <div className="overview">
            <DonorSearchFieldFilters
              customFields={customFields}
              dayOptions={dayOptions}
              monthOptions={monthOptions}
              yearOptions={yearOptions}
              tags={tags}
              onSubmit={this.props.onSubmit}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default DonorSearchFieldFiltersDropDown;
