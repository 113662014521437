import React from 'react';
import moment from 'moment';
import formatCurrency from '../../utils/format-currency';

class NonprofitDashboardTransactionList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      transactions: [],
      isLoading: false,
      isDataError: false
    }
  }

  typeLabel(type) {
    if (type === 'order') {
      return 'registration';
    }

    return type;
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    const params = {
      api_key: this.props.apiKey
    }

    $.get('/api/nonprofits/transactions/recent', params)
      .done((data) => {
        this.setState({ transactions: data });
      })
      .fail((error) => {
        this.setState({ isDataError: true });
      })
      .always(() => {
        this.setState({ isLoading: false });
      });
  }

  renderTransactions() {
    const { transactions } = this.state;

    if (transactions.length === 0) {
      return <p className="dashboard__no-activity p-5">No recent activity.</p>;
    }

    return transactions.map((transaction) => {
      const typeLabel = this.typeLabel(transaction.type);

      return (
        <a
          key={transaction.id}
          href={transaction.url}
          className="flex items-center justify-between py-4 px-5 text-neutral-900 hover:bg-neutral-100">
          <div className="w-full">
            <p className="text-xs">
              {moment.parseZone(transaction.transacted_at).format('MMM D, YYYY')}
            </p>
            <h4 className="font-medium">
              {transaction.name}
            </h4>
          </div>
          <div className="w-36 sm:w-44 shrink-0 flex justify-between items-center">
            <div className="badge badge--green" title={`${typeLabel}`}>
              {`${typeLabel}`}
            </div>
            <p className="dashboard-activity__amount">
              {formatCurrency(transaction.total_in_cents)}
            </p>
          </div>
        </a>
      );
    });
  }

  renderBody() {
    if (this.state.isLoading) {
      return <div className="dashboard-loading text-sm text-neutral-600">Loading...</div>;
    }

    if (this.state.isDataError) {
      return <p className="dashboard__error p-5">This data cannot be shown at this time.</p>;
    }

    return this.renderTransactions();
  }

  render() {
    const body = this.renderBody();

    return (
      <div className="dash-recent-activity dash-block h-full">
        <header className="py-4 px-5 border-b border-solid border-neutral-200">
          <h3 className="text-h5">
            Recent Activity
          </h3>
        </header>
        <div className="divide-y divide-neutral-200 divide-solid">
          {body}
        </div>
      </div>
    );
  }
}

export default NonprofitDashboardTransactionList;
