import React from 'react';
import Modal from '../modal';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';

class DonorProfileTagsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: this.props.tags,
      value: '',
      suggestions: this.props.suggestions,
      isDataError: false,
      isDisabled: false,
      isLoading: false
    }

    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.submitTag = this.submitTag.bind(this);
    this.addTag = this.addTag.bind(this);
    this.update = this.update.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  addTag(tagName) {
    const tags = this.state.tags;

    if (tags.indexOf(tagName) > -1) {
      return;
    }

    tags.push(tagName);
    this.setState({ tags: tags });
  }

  cancel() {
    this.props.onCancel();
  }

  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  getSuggestions(value) {
    const escapedValue = this.escapeRegexCharacters(value.trim());
    const regex = new RegExp('^' + escapedValue, 'i');

    const availableSuggestions = this.props.suggestions.filter(tag => {
      return this.state.tags.indexOf(tag) === -1;
    });

    const suggestions = availableSuggestions.filter(tag => regex.test(tag));

    if (!suggestions.length) {
      return [
        { isAddNew: true }
      ]
    }

    return suggestions;
  }

  getSuggestionValue(suggestion) {
    if (suggestion.isAddNew) {
      return this.state.value;
    }

    return suggestion;
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: []
    });
  }

  onSuggestionsFetchRequested({ value }) {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  }

  onSuggestionSelected(event, { suggestionValue }) {
    if (!suggestionValue) {
      return false;
    }

    this.addTag(suggestionValue);
    this.setState({ value: '' });
  }

  onChange(event, { newValue }) {
    this.setState({ value: newValue });
  }

  removeTag(tagName) {
    let tags = this.state.tags;
    tags = tags.filter(tag => tag != tagName);
    this.setState({ tags: tags });
  }

  shouldRenderSuggestions() {
    return true;
  }

  submitTag(event) {
    event.preventDefault();

    const tag = this.state.value;

    if (tag.length === 0) { return; }

    this.addTag(tag);
    this.setState({ value: '' });
  }

  update() {
    const { value } = this.state;
    const { onUpdate } = this.props;

    if (value) { this.addTag(value); }

    this.setState({ isLoading: true, isDisabled: true });
    onUpdate(this.state.tags);
  }

  renderError() {
    if (this.state.isDataError) {
      return <p className="dashboard__error">This data cannot be shown at this time.</p>;
    }

    return null;
  }

  renderSuggestion(suggestion, { query, isHighlighted }) {
    let itemClassName, content, name;

    if (isHighlighted) {
      itemClassName = 'is-highlighted';
    }

    if (suggestion.isAddNew) {
      name = this.state.value;
      content = (
        <span>
          [+] Add new: <strong>{this.state.value}</strong>
        </span>
      );
    } else {
      name = suggestion;
      content = suggestion;
    }

    return (
      <div data-tags-suggestion={name} className={`autosuggest-item ${itemClassName}`}>
        <span className="autosuggest-item__name">
          {content}
        </span>
      </div>
    )
  }

  renderTags() {
    return this.state.tags.map((tag, index) => {
      return (
        <div key={index} className="tag-item" data-tag-item={tag}>
          <span className="tag-item__label">{tag}</span>
          <button
            onClick={this.removeTag.bind(this, tag)}
            data-tag-remove={tag}
            className="tag-item__remove">
              <span className="sr-only">
                Delete
              </span>
          </button>
        </div>
      )
    });
  }

  render() {
    const { isLoading, isDisabled } = this.state;
    const error = this.renderError();
    const tags = this.renderTags();

    const inputProps = {
      placeholder: "Start typing...",
      value: this.state.value,
      onChange: this.onChange,
      'data-tags-input': 'true',
      autoFocus: false,
      maxLength: 255
    }

    const modalAttrs = { 'data-donor-tags-modal': 'true' }

    return (
      <Modal
        onCancel={this.cancel}
        onUpdate={this.update}
        title="Edit Tags"
        attrs={modalAttrs}
        isDisabled={isDisabled}
        isLoading={isLoading}
      >
        {error}
        <form onSubmit={this.submitTag} className="tags-form">
          <Autosuggest
            suggestions={this.state.suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionSelected={this.onSuggestionSelected}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            inputProps={inputProps}
            shouldRenderSuggestions={this.shouldRenderSuggestions}
            highlightFirstSuggestion={true}
            focusInputOnSuggestionClick={false}
          />
        </form>

        <div data-tags-collection-form className="tags-list">
          {tags}
        </div>
      </Modal>
    )
  }
}

export default DonorProfileTagsForm;
