import React from 'react';
import DonorSearchFilterFieldsSelect from './field-filters/select';
import DonorSearchFilterValueFields from './field-filters/value-fields';
import DonorSearchFilterSubmit from './field-filters/submit';
import filterMenuType from '../../utils/donor-search/filter-menu-type';
import filterMenuValue from '../../utils/donor-search/filter-menu-value';

class DonorSearchFieldFilters extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    // So we can reset to the
    // original state later
    this.baseState = this.state

    this.changeField = this.changeField.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.submit = this.submit.bind(this);
  }

  getInitialState() {
    if (this.props.tags.length) {
      return {
        field: 'nonprofit_tag_ids_in',
        type: 'tag',
        value: this.props.tags[0].id
      }
    }

    return {
      field: 'first_name_cont_any',
      type: 'text',
      value: ''
    }
  }

  changeField(event) {
    const field = event.target.value;
    const type = filterMenuType(field);
    const value = filterMenuValue(field);

    this.setState({
      field,
      type,
      value
    });
  }

  changeValue(value) {
    this.setState({ value });
  }

  submit(event) {
    event.preventDefault();

    const { onSubmit } = this.props;
    const { field, value } = this.state;

    onSubmit({ field: field, value: value });

    this.setState(this.baseState);
  }

  render() {
    const {
      customFields,
      dayOptions,
      monthOptions,
      tags,
      yearOptions
    } = this.props;

    const { field, type, value } = this.state;

    return (
      <>
        <DonorSearchFilterFieldsSelect
          customFields={customFields}
          onChange={this.changeField}
          tags={tags}
          value={field}
        />

        <DonorSearchFilterValueFields
          dayOptions={dayOptions}
          monthOptions={monthOptions}
          onChange={this.changeValue}
          tags={tags}
          type={type}
          value={value}
          yearOptions={yearOptions}
        />

        <DonorSearchFilterSubmit
          onClick={this.submit}
        />
      </>
    )
  }
}

export default DonorSearchFieldFilters;
