import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['select', 'input'];

  connect() {
    this.render();
  }

  changeType() {
    this.render();
  }

  render() {
    if ($(this.selectTarget).val() === 'external_link') {
      $(this.inputTarget).show();
    } else {
      $(this.inputTarget).hide();
    }
  }
}